import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Eko from "/src/components/img/eko"
import ImgCon from "/src/components/img-con"
import SEO from "/src/components/seo"
import BtnWrap from "/src/components/btn-wrap"

export default () => (
  <Layout>
    <SEO title="Lesnická legislativa" />
    <h1>Lesnická legislativa</h1>
    <p>Pro <strong>lesnickou legislativu</strong> je v&nbsp;současnosti klíčový <strong>Lesní zákon 289/1995 Sb.</strong> V&nbsp;roce 1996 bylo vydáno 11 vyhlášek, které upravují hospodaření a správu lesů. Podrobněji byla legislativa rozebírána na semináři.</p>
    <hr />
    <h2>Kompetence v oblasti lesnictví</h2>
    <p>Kompetence v&nbsp;oblasti lesnictví má především ministerstvo zemědělství (MZe). Dále pak ministerstvo životního prostředí (MŽP), které má ve správě lesy v&nbsp;národních parcích, národních přírodních rezervacích a národních přírodních památkách. Rovněž pod něj spadá inspekce životního prostředí (kontrolní orgán).</p>
    <p>Střet kompetencí často vede k „válkám“ mezi MZe a MŽP v&nbsp;přístupu ke správě lesů, což je jeden z&nbsp;důvodů, proč se situace v&nbsp;lesích příliš nelepší.</p>
    <p>Dále má jisté pravomoci i krajský úřad, kde se například referent životního prostředí vyjadřuje k&nbsp;lesním hospodářským plánům. Kraje též spravují přírodní rezervace a přírodní památky.</p>
    <hr />
    <h2>Výběr vyhlášek ministerstva zemědělství</h2>
    <p><strong>Vyhláška MZe č. 83/1996 Sb.</strong>, tzv. HÚLka (hospodářská úprava lesů), se zabývá <strong>zpracováním OPRL a vymezením hospodářských souborů</strong>.</p>
    <p>Hospodářskou úpravou lesů se zabývá <strong>ÚHUL</strong>, aneb Ústav pro hospodářskou úpravu lesů, který sídlí v&nbsp;Brandýse nad Labem. Jeho pobočky jsou kolikrát zajímavě umístěny (např. Jablonec nad Nisou, Písek) a jejich kompetence se nekryjí s&nbsp;kraji.</p>
    <p>V&nbsp;této vyhlášce se řeší i provedení a náplň <strong>OPRL</strong> (Oblastní plán rozvoje lesa), který vypracovává ÚHUL. Jsou metodickým nástrojem státní lesnické politiky a doporučují zásady hospodaření v lesích, především při tvorbě a schvalování lesních hospodářských plánů a osnov.</p>
    <p>OPRL jsou vypracovány pro jednotlivé přírodní lesní oblasti (PLO), aby zohledňovali místní podmínky, s&nbsp;platností na 20 let.</p>
    <p><strong>Vyhláška</strong> <strong>MZe č. 84/1996 Sb.</strong> <strong>o lesním hospodářském plánování </strong>se týká detailů kolem plánování péče o les a těžby dřeva. Mimo jiné stanovuje podíly melioračních a zpevňujících dřevin, neříká však, jak dlouho v&nbsp;lese mají tyto dřeviny růst a jak mají být vysazeny. Zabývá se také například zajištěním ekologické i&nbsp;statické stability lesa.</p>
    <hr />
    <h2>Dokumenty lesnického plánování</h2>
    <p>Mezi klíčové patří <strong>OPRL</strong>, oblastní plán rozvoje lesa, který je popisován výše.</p>
    <p>Dalším dokumentem je <strong>LHP</strong>, lesní hospodářský plán zpracovávaný na 10 let. Ten de facto funguje v&nbsp;různých formách již od dob Marie Terezie. Je povinnost si ho nechat zpracovat a zaplatit pro majetky nad 50 ha. Obsahuje informace o každé porostní skupině (skupina nad 20x20 m, tvořena alespoň jednou etáží).</p>
    <p>Lesnická taxační kancelář změří stromy, kubaturu dřeva v&nbsp;lese, určí druhovou strukturu, typologii a zpracují plán, co s&nbsp;daným majetkem v&nbsp;10 letech dělat. Určí kolik vykácet, aby se směřovalo k&nbsp;těžební vyrovnanosti (co vyroste, to skácím). Naplánuje i zákonně povinné zalesnění holiny do 2 let (způsob a jaké druhy).</p>
    <p>Veřejně přístupné pro státní majetky (dobrý zdroj informací), soukromník musí dát souhlas se zveřejněním.</p>
    <p>Velmi zjednodušenou verzí LHP jsou <strong>LHO</strong>, lesní hospodářské osnovy. Ty jsou taktéž zpracovávány na 10 let, ale pro majetky, které jsou menší než 50 ha. Jejich vyhotovení majiteli proplatí stát.</p>
    <hr />
    <ImgCon><Eko /><div>
    <h2>Studijn&iacute; materi&aacute;ly Ekologie a ŽP</h2>
    <p>T&eacute;ma <strong>Lesnická legislativa</strong> je souč&aacute;st&iacute; <Link to="/ekologie-zivotni-prostredi/">studijn&iacute;ch materi&aacute;lů Ekologie a životní prostředí</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte n&aacute;sleduj&iacute;c&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/ekologie-zivotni-prostredi/"><button>Rozcestník ekologie a ŽP</button></Link>
    <Link to="/ekologie-zivotni-prostredi/historie-lesu/"><button className="inv">&larr; Historie lesů na území ČR</button></Link>
    <Link to="/ekologie-zivotni-prostredi/klasifikace-lesu/"><button className="inv">Klasifikace lesů v ČR &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />

  </Layout>
)
